.heroslider {
	height: 300px;
	.slide {
		background: no-repeat center center;
		background-size: cover; } }

.hero {
	position: relative;
	z-index: 1;
	padding: 70px 0;
	height: 200px;
	background: url('../dest/img/hero.jpg') no-repeat center center;
	background-size: cover;
	color: #ffffff;
	@media (max-width: map-get($breakpoints, m)) {
		text-align: center; }
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: rgba(0,0,0,.2); }
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: -3px;
		right: 3px;
		left: 3px;
		border-bottom: 1px dashed #999;
		background: #ffffff; }
	.title {
		@extend h1;
		margin-bottom: 0;
		color: #ffffff; } }
