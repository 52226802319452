.overheader {
	padding: 0.4em 0 0.2em;
	font-size: 0.8em;
	text-align: right;
	background: #eee;
	li {
		padding: 0;
		display: inline-block;
		margin-left: 20px;
		&:first-child {
			margin-left: 0; }
		&:before {
			content: none; }
		a {
			color: inherit;
			&:hover {
				color: $primary-color; }
			//i
 } } }			//olor: $primary-color

.header {
	padding: 20px 0;
	background: #fff;
	@media (max-width: map-get($breakpoints, l)) {
		padding-bottom: 50px; }
	//@media (min-width: map-get($breakpoints, l))
	//scrolled &
	//position: fixed
	//top: 0
	//left: 0
	//right: 0
	//z-index: 3
	//padding-bottom: 25px
	//box-shadow: 0 2px 4px rgba(0,0,0,.3)
	//animation: fadedown .2s
	.logo {
		@media (max-width: map-get($breakpoints, l)) {
			text-align: center; }
		a {
			display: inline-block;
			img {
				display: block;
				height: 80px; } } }
	.navigation {
		padding-top: 30px;
		ul {
			text-align: right;
			li {
				display: inline-block;
				position: relative;
				margin: 0 0 0 20px;
				padding: 0;
				color: #666666;
				&:hover {
					color: $primary-color; }
				//.scrolled &
				//adding-top: 35px
				&:first-child {
					margin-left: 0; }
				&:before {
					content: none; }
				a {
					display: block;
					padding-bottom: 20px;
					color: inherit;
					&.current, &.section {
						font-weight: bold;
						color: $primary-color; } }

				&:hover ul {
					opacity: 1;
					max-height: 999px;
					border-top: 3px solid $primary-color;
					padding: 0;
					transform: translateY(0px);
					overflow: visible;
					transition-timing-function: linear, linear, step-start, step-start; }
				> ul {
					display: block;
					opacity: 0;
					position: absolute;
					left: 0;
					top: 100%;
					z-index: 2;
					padding: 0;
					width: 200px;
					max-height: 0;
					transform: translateY(20px);
					overflow: hidden;
					text-align: left;
					background: #fff;
					box-shadow: 1px 1px 4px rgba(0,0,0,.3);
					transition: opacity .2s, transform .2s, max-height .2s step-end, padding .2s step-end;
					li {
						margin: 0;
						border: 0;
						padding: 0;
						display: block;
						//.scrolled &
						//adding-top: 0
						a {
							padding: 7px 20px;
							font-size: 14px;
							text-transform: none;
							font-weight: normal;
							&:hover {
								background: lighten($secondary-color, 1%);
								color: #ffffff; } }

						&:first-child a {
							border-top: none; }
						&:last-child a {
							border-bottom: none; } }
					ul {
						display: none; } }
				&:last-child > ul {
					left: auto;
					right: 0; } } } } }



//Mobile navigation
.mobileNav {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 5;
	padding: 5px 20px;
	font-size: 20px;
	text-align: right;
	color: #ffffff;
	background: rgba($primary-color, .8);
	a {
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px; } }
	@media (max-width: map-get($breakpoints, l)) {
		display: block; } }
//
//mobileNav a
//display: block
//width: 60px
//height: 45px
//position: relative
//margin: 50px auto
//transform: rotate(0deg)
//transition: .5s ease-in-out
//cursor: pointer

//mobileNav a span
//display: block
//position: absolute
//height: 9px
//width: 100%
//background: #d3531a
//border-radius: 9px
//opacity: 1
//left: 0
//transform: rotate(0deg)
//transition: .25s ease-in-out


//mobileNav a span:nth-child(1)
//top: 0px

//mobileNav a span:nth-child(2), .mobileNav a span:nth-child(3)
//top: 18px

//mobileNav a span:nth-child(4)
//top: 36px

//mobileNav a.open span:nth-child(1)
//top: 18px
//width: 0%
//left: 50%

//mm-opened .mobileNav a span:nth-child(2)
//transform: rotate(45deg)

//mm-opened .mobileNav a span:nth-child(3)
//transform: rotate(-45deg)

//mm-opened .mobileNav a.open span:nth-child(4)
//top: 18px
//width: 0%
//left: 50%
