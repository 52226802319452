//Colors
$primary-color: #2980b9;
$secondary-color: $primary-color;


//Feedback colors
$alert-color: #e74c3c;
$success-color: #2ecc71;
$warning-color: #f1c40f;


//Fonts
$heading-font: 'Montserrat', sans-serif;
$body-font: 'Montserrat', sans-serif;

$breakpoints: (s: '0px', m: '640px', l: '1024px');

//Forms
$form-spacing-between: 30px;
$form-spacing-underneath: 20px;

@import "mixin";

@import "grid";
@include grid(12, 3);

//Placeholders
%button {
	display: inline-block;
	border: none;
	padding: 0.8em 2em;
	min-width: 140px;
	font-family: $body-font;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	color: #fff;
	background: $secondary-color;
	border-radius: 0.5em;
	transition: all .2s;
	cursor: pointer;
	&:hover {
		color: #fff;
		background: darken($secondary-color, 5%); }
	&:focus {
		outline: none; }
	&:active {
		transition: all .1s;
		background: darken($secondary-color, 20%); } }

//Animations
@keyframes fadedown {
	0% {
		opacity: 0;
		transform: translateY(-50%); }
	100% {
		opacity: 1;
		transform: translateY(0%); } }

@keyframes pop {
	0% {
		transform: scale(0); }
	90% {
		transform: scale(1.2); }
	100% {
		transform: scale(1); } }
