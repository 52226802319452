//Normalize fixes
fieldset {
	border: 0;
	margin: 0;
	padding: 0; }


.mm-menu.mm-front {
	z-index: 10;
	background: $primary-color;
	li {
		list-style: none;
		&:before {
			content: none; } } }
//Lightgallery styling
.lg-backdrop {
	background: rgba($secondary-color,.8); }
.lg-actions .lg-next, .lg-actions .lg-prev {
	background: none; }
.lg-sub-html, .lg-toolbar {
	background: $secondary-color; }

//Fix to lightgallery icons
.lg-actions .lg-icon {
	font-family: 'FontAwesome';
	color: #ffffff;
	&.lg-close:after {
		content: "\f00d" !important; }
	&.lg-prev:after {
		content: "\f053" !important; }
	&.lg-next:before {
		content: "\f054" !important; } }

.swiper-pagination-bullet {
	background: #ffffff; }
