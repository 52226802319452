.CommentPage {
	.content {
		@extend %coloredArea;
		.comment {} }
	.formholder {
		margin-top: 0;
		padding-bottom: 50px;
		border-bottom: 1px solid #ddd;
		h2 {
			margin-bottom: 20px; } }
	.comments {
		padding: 50px 0; }
	.commentlist {
		padding-top: 50px;
		.title {
			@extend h2;
			text-transform: uppercase;
			text-align: center; }
		.comment {
			margin-bottom: 20px;
			padding: 10px 20px;
			color: #ffffff;
			border-radius: 4px;
			background: lighten($primary-color, 20%);
			&:after {
				content: '';
				display: block;
				clear: both; }
			.author {
				float: right;
				padding: 0 0 20px 20px; }
			.name {
				font-weight: bold;
				font-size: 1.1em; }
			.email {
				font-style: italic;
				font-size: 0.8em; } } } }
