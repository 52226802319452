.boardMembers {
	@extend %coloredArea;
	padding: 50px 0;
	text-align: center;
	.title {
		@extend h2;
		margin-bottom: 20px;
		text-transform: uppercase; }
	.image {
		margin-bottom: 10px;
		img {
			display: inline-block;
			width: 80%;
			border-radius: 50%; } }
	.member {
		.name {
			color: $primary-color;
			font-weight: bold; }
		a:hover {
			color: $primary-color; } } }
.contactForm {
	padding: 50px 0;
	.formText {
		margin-bottom: 40px; } }
