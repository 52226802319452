//TinyMCE styles
body.mceContentBody {
	font-size: 13px; }


//Fonts
body {
	font-family: $body-font;
	font-weight: 300;
	font-size: 15px;
	color: #4A4A4A;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased; }

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	font-family: $heading-font;
	font-weight: 700;
	line-height: 1.2;
	color: #4A4A4A; }

h1 {
	margin-bottom: 20px;
	font-size: 48px;
	font-weight: 400;
	&:last-child {
		margin-bottom: 0; }
	.hero & {
		margin-bottom: 0;
		color: #ffffff;
		@media not all and (min-width: map-get($breakpoints, m)) {
			text-align: center; } }
	.HomePage .hero & {
		&:after {
			content: none; } } }

h2, h3, h4, h5, h6 {
	position: relative;
	margin-bottom: 13px;
	font-size: 25px;
	line-height: 1.3;
	font-family: $heading-font;
	@media not all and (min-width: map-get($breakpoints, m)) {
		text-align: center; } }

h3, h4, h5, h6 {
	font-size: 20px;
	color: $primary-color; }

a {
	color: inherit;
	text-decoration: none; }

p, ul {
	margin-bottom: 15px;
	padding-left: 0;
	list-style: none;
	&:last-child {
		margin-bottom: 0; }
	&.small {
		margin-bottom: 10px;
		font-size: 12px;
		+ p {
			margin-top: 20px; }
		+ p.small {
			margin-top: 10px; } }
	a {
		color: $primary-color;
		transition: color .2s;
		&:hover {
			color: lighten($primary-color, 20%); } }
	li {
		position: relative;
		margin-bottom: 5px;
		padding-left: 50px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0.6em;
			left: 20px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: $primary-color; } }
	+ h2 {
		margin-top: 30px; }
	+ h3 {
		margin-top: 40px; } }

.textcolumns {
	column-count: 2; }



a.button {
	@extend %button;
	font-size: 0.8em; }

a.arrow {
	&:after {
		@extend .fa;
		@extend .fa-arrow-circle-right:before;
		margin-left: 0.5em;
		font-size: 0.86em;
		transition: all .2s; }
	&:hover:after {
		opacity: 0;
		transform: translateX(30px); } }
