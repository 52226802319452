.MemberPage {
	.memberGallery {
		padding: 70px 0;
		.member {
			margin-bottom: 3%;
			padding: 0; }
		.title {
			@extend h2;
			margin-bottom: 40px;
			text-transform: uppercase;
			text-align: center; } }
	.content {
		@extend %coloredArea;
		text-align: center; } }
.member-list {
	.image {
		display: block;
		position: relative;
		border-radius: 50%;
		overflow: hidden;
		.overlay {
			content: '';
			opacity: 0;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: rgba($primary-color, .8);
			border-radius: 50%;
			text-align: center;
			color: #ffffff;
			transition: all .2s;
			.name {
				@extend h3;
				font-size: 15px;
				padding: 30% 3% 0;
				color: inherit;
				text-transform: uppercase; }
			.mappos, .galleryLink.first {
				display: inline-block;
				margin: 0 .25em;
				width: 2em;
				height: 2em;
				line-height: 2em;
				text-align: center;
				font-size: 20px;
				color: $primary-color;
				background: #fff;
				border-radius: 50%;
				&:hover {
					background: #333;
					color: #ffffff; } } }
		.MemberPage &:hover {
			.overlay {
				opacity: 1; }
			img {
				transform: scale(1.1); } }
		.placeholderImage {
			width: 100%;
			height: 0;
			padding-bottom: 100%;
			background: url('img/map-marker.svg') no-repeat center center;
			background-size: 30%; }
		img {
			display: block;
			transition: all .2s; }
		.name {
			margin-top: 10px;
			font-size: 14px;
			color: $primary-color; }
		.boatname,.sailnr,.homeport {
			font-size: 13px; } } }
