.footer {
	border-top: 8px solid $secondary-color;
	padding: 45px 0 70px;
	color: #C5C5C5;
	font-size: 13px;
	background: #313C40;
	.logo {
		img {
			display: block;
			margin: 0 auto;
			width: 70px; } }
	.heading {
		margin-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		color: #fff; }
	ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap; }
	li {
		display: block;
		width: 50%;
		padding: 0;
		color: #ffffff;
		&:before {
			display: none; }
		a {
			color: #fff;
			&:hover {
				color: $primary-color; } } } }
