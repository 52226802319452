 :-ms-input-placeholder {
	color: #6C6C6C !important;
	opacity: 0.8; }

.formholder {
	margin-top: 40px; }

form {
	position: relative;
	margin-left: -#{$form-spacing-between};
	width: calc( 100% + #{$form-spacing-between} );
	&:after {
		content: '';
		display: block;
		clear: both; }
	.field {
		float: left;
		margin-bottom: $form-spacing-underneath;
		margin-left: $form-spacing-between;
		width: calc( 100% - #{$form-spacing-between} );
		&.half {
			width: calc( 50% - #{$form-spacing-between} ); }
		&.third {
			width: calc( 33.33% - #{$form-spacing-between} ); }
		&last-child {
			margin-bottom: 0; } }
	label {
		display: block;
		margin-bottom: $form-spacing-underneath / 8;
		color: #6c6c6c; }
	input,textarea,select {
		display: block;
		border: 1px solid #cacaca;
		padding: 8px 13px;
		width: 100%;
		max-width: 100%;
		border-radius: 4px;
		font-size: 14px;
		font-family: $body-font;
		color: #6C6C6C;
		background: #fefefe;
		box-shadow: inset 0 1px 3px 0 rgba(10,10,10,0.09);
		resize: none;
		transition: all .2s;
		&:focus {
			outline: none;
			box-shadow: inset 0px 1px 3px 0 rgba(10,10,10,0.5); }
		&::placeholder {
			color: rgba(#6C6C6C, .8); }
		&[type="radio"],&[type="checkbox"] {
			display: inline-block;
			margin-right: 5px;
			width: auto;
			+ label {
				display: inline-block;
				font-size: 14px;
				color: #6c6c6c; } } }
	li {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		&:last-child {
			margin-right: 0; }
		&:before {
			content: none; } }
	textarea {
		height: 150px; }
	select {
		padding-right: 20px;
		appearance: none;
		background-image: url('img/form-select.svg');
		background-repeat: no-repeat;
		background-position: right 15px center;
		&:after {
			content: '';
			display: block;
			border: 5px solid transparent;
			border-top-color: #666; }
		option {
			background: #ffffff; }
		&::-ms-expand {
			display: none; } }
	button[type="submit"] {
		@extend %button;
		float: right;
		padding: 0.5em 2em;
		font-size: 16px; }
	.ContactPage & .Actions {
		text-align: right; }
	.message {
		display: block;
		margin-bottom: 5px;
		margin-left: $form-spacing-between;
		padding: 5px 10px;
		border-radius: 3px;
		font-weight: bold;
		font-family: sans-serif;
		&.good {
			background: $success-color;
			color: #ffffff; }
		&.bad {
			background: $alert-color;
			color: #ffffff; }
		&.validation, &.required {
			background: $warning-color;
			color: #ffffff; } }

	label.form-heading {
		display: block;
		clear: both;
		margin-left: #{$form-spacing-between};
		margin-bottom: 13px;
		font-size: 11px;
		font-weight: normal;
		text-transform: uppercase;
		color: #4d5051; } }
