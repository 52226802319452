@mixin grid($grid-columns: 12, $grid-gutter-size: 3) {

	$colWidth: 100 / $grid-columns;

	$colSpace: $grid-gutter-size * 100 / ( 100 + $grid-gutter-size );

	.row {
		margin-left: - ( $grid-gutter-size ) * 1%;
		width: $grid-gutter-size + 100%;
		&:before, &:after {
			content: '';
			display: block;
			clear: both; }
		> .end {
			float: right; } }
	@for $i from 1 through $grid-columns {
		@each $breakpoint, $width in $breakpoints {
			.row > .#{$breakpoint}#{$i} {
				margin-left: $colSpace * 1%;
				float: left;
				@media (min-width: $width) {
					width: ( $colWidth * $i - $colSpace ) * 1%; }
				&.#{$breakpoint}center {
					@media (min-width: $width) {
						margin-left: (( $colWidth * (($grid-columns - $i)/2) - $colSpace) + ( $colSpace * 2 )) * 1%; } } }

			.row > .#{$breakpoint}push#{$i} {
				position: relative;
				@media (min-width: $width) {
					left: $colWidth * $i * 1%; } }

			.row > .#{$breakpoint}pull#{$i} {
				position: relative;
				@media (min-width: $width) {
					right: $colWidth * $i * 1%; } }

			.row > .#{$breakpoint}offset#{$i} {
				@media (min-width: $width) {
					margin-left: (( $colWidth * $i - $colSpace) + ( $colSpace * 2 )) * 1%; } } } }

	@each $breakpoint, $width in $breakpoints {
		.row > .#{$breakpoint}reset {
			@media (min-width: $width) {
				margin-left: $colSpace * 1% !important; } } } }

@each $breakpoint, $width in $breakpoints {
	.#{$breakpoint}hide {
		@media (min-width: $width) {
			display: none; } }

	.#{$breakpoint}show {
		@media (min-width: $width) {
			display: block; } } }

